import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Form, Select, Input, Spin, Checkbox, Radio } from 'antd';
import { EgGrid } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type ManuallyShipStore from './store';

const formLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
};

const ManuallyShipModal: React.FC<{ store: ManuallyShipStore; }> = observer((props) => {
  const { modalLoading,
    confirmLoading,
    confirmDisable,
    gridModel,
    visible,
    type,
    onModalClose,
    setRef,
    onOk,
    courierOptions } = props.store;
  return (
    <Modal
      className={styles.manuallyShip}
      confirmLoading={confirmLoading}
      okButtonProps={{ disabled: confirmDisable }}
      okText="确认发货"
      onCancel={onModalClose}
      onOk={onOk}
      open={visible}
      title="手动发货"
      width={800}
    >
      <Spin spinning={modalLoading}>
        {type === 'select' && (
          <div className={styles.tips}>
            <InfoCircleOutlined className={styles.infoIcon}/>
            发货数量小于下单数量，未发货商品将生成未发货仅退款售后单并原路退款至零售商
          </div>
        )}
        <div className={styles.grid}>
          <EgGrid store={gridModel}/>
        </div>
        <Form
          {...formLayout}
          ref={setRef}
        >
          <Form.Item
            label="快递公司"
            name="courier"
            rules={[{ required: true }]}
          >
            <Select options={courierOptions}/>
          </Form.Item>
          <Form.Item
            label="快递单号"
            name="courierNumber"
            rules={[{ required: true }]}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            initialValue
            label="是否结算给供应商"
            name="settle"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value>
                是
              </Radio>
              <Radio value={false}>
                否
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
});

export default ManuallyShipModal;
