import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { observable, action } from 'mobx';
import api from '../../../../utils/api';
import type ChooseStyleStore from '../../store';

export default class RemarkModalStore {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public parent: ChooseStyleStore;

  @observable public visible = false;

  @observable public confirmLoading = false;

  @observable private type: 'select' | 'distribute' = 'select';

  @observable private ref: FormInstance;

  @observable private orderId: number | string;

  private APIs = {
    select: api.editRemark,
    distribute: api.editDistributeRemark,
  };

  @action public setRef = (ref: FormInstance) => {
    this.ref = ref;
  };

  @action public onModalShow = (type: 'select' | 'distribute', remark: string, orderId: number | string): void => {
    Object.assign(this, {
      visible: true,
      type,
      orderId,
    });
    setTimeout(() => {
      this.ref?.setFieldsValue({ remark });
    }, 0);
  };

  @action public onCloseModal = (): void => {
    Object.assign(this, {
      visible: false,
      type: '',
      orderId: null,
    });
    this.ref?.resetFields();
  };

  @action public onOk = (): void => {
    const { remark } = this.ref?.getFieldsValue(['remark']);
    this.confirmLoading = true;
    request({
      url: this.APIs[this.type],
      method: 'post',
      data: {
        orderId: this.orderId,
        remark,
      },
    }).then((res: BaseData<unknown>) => {
      message.success(res?.info || '操作成功');
      this.parent?.handleQuery();
      this.onCloseModal();
    })
      .finally(() => {
        this.confirmLoading = false;
      });
  };
}
