import { Card, Spin, Button } from 'antd';
import { ExportModal, NormalProgrammeComponent } from 'egenie-utils';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { OrderGrid } from '../components';
import OrderQueryModal from './components/courierQuery/courierQueryModal';
import ManuallyShipModal from './components/manuallyShip';
import RemarkModal from './components/remarkModal';
import ReturnGoods from './components/returnGoods';
import TabHader from './components/tabs';
import styles from './index.less';
import Store from './store';

const store = new Store();

@observer
export default class SelectOrder extends Component {
  render(): JSX.Element {
    const { programme, loading, returnGoodsStore, exportOrder } = store;
    return (
      <div className={styles.soWrapper}>
        <Spin spinning={loading}>
          <TabHader store={store}/>
          <div className={styles.queryWrapper}>
            <Card>
              <NormalProgrammeComponent store={programme}/>
            </Card>
          </div>
          <div style={{ marginBottom: '4px' }}>
            <Button
              onClick={exportOrder}
              size="small"
            >
              导出
            </Button>
          </div>
          <div className={styles.galleryTableContent}>
            {/* <MainList store={store}/> */}
            <OrderGrid store={store.orderGridStore}/>
          </div>
        </Spin>
        <ReturnGoods store={returnGoodsStore}/>
        <ExportModal store={store.exportStore}/>
        <ManuallyShipModal store={store.manuallyShipStore}/>
        <RemarkModal store={store.remarkModalStore}/>
        <OrderQueryModal store={store.courierQueryStore}/>
      </div>
    );
  }
}
