
import { FormOutlined } from '@ant-design/icons';
import { Button, Popover, Form, InputNumber, Space, Switch, message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import React, { useRef, useState } from 'react';
import styles from './index.less';

// 修改价格
const EditPrice: React.FC<{
  mallSaleOrderId: number;
  mallSaleOrderDetailId: number;
  price: number;
  openUpdateSame: boolean;
  changeOpenUpdateSame: (openUpdateSame: boolean) => void;
  handleQuery: () => Promise<unknown>;
}> = ({ price, openUpdateSame, changeOpenUpdateSame, mallSaleOrderId, mallSaleOrderDetailId, handleQuery }) => {
  const [
    disable,
    setDisable,
  ] = useState(false);
  const formRef = useRef(null);
  const editRef = useRef(null);

  const onVisibleChange = (visible: boolean) => {
    if (visible) {
      setTimeout(() => {
        formRef.current?.setFieldsValue({
          price,
          openUpdateSame,
        });
      });
    }
  };

  const clickOk = (): void => {
    formRef.current?.validateFields().then((res) => {
      const values = formRef.current?.getFieldsValue();
      setDisable(true);
      request({
        url: '/api/mall/operation/rest/mall/saleOrder/changePrice',
        method: 'POST',
        data: {
          mallSaleOrderDetailId,
          mallSaleOrderId,
          newPrice: values.price,
          updateSameGoods: Number(openUpdateSame),
        },
      })
        .then((res: BaseData<unknown>) => {
          if (res.status === 'Successful') {
            clickCancel();
            handleQuery();
          }
          message.success(res.info);
        })
        .finally(() => {
          setDisable(false);
        });
    });
  };

  const clickCancel = (): void => {
    editRef.current?.click();
    formRef.current?.resetFields();
  };

  const onSwitchChange = (checked): void => {
    changeOpenUpdateSame(checked);
  };

  const content = (
    <div className={styles.editPricePopover}>
      <Space>
        <Form
          labelCol={{ span: 8 }}
          layout="horizontal"
          ref={formRef}
        >
          <Form.Item
            name="price"
            rules={[
              {
                required: true,
                message: '请输入价格',
              },
            ]}
          >
            <InputNumber
              min={0.01}
              placeholder="请输入单价"
              precision={2}
              width={160}
            />
          </Form.Item>
        </Form>
        <Button
          disabled={disable}
          ghost
          onClick={clickOk}
          type="primary"
        >
          确定
        </Button>
        <Button onClick={clickCancel}>
          取消
        </Button>
        <Switch
          checked={openUpdateSame}
          checkedChildren="开"
          onChange={onSwitchChange}
          unCheckedChildren="关"
        />
        <span style={{ marginLeft: 8 }}>
          更新同款
        </span>
      </Space>

    </div>
  );
  
  return (
    <span>
      <Popover
        content={content}
        destroyTooltipOnHide
        getPopupContainer={(triggerNode) => (triggerNode.parentElement)}
        onVisibleChange={onVisibleChange}
        trigger="click"
      >
        <FormOutlined
          className={styles.editPrice}
          ref={editRef}
        />
      </Popover>
    </span>
  );
};

export default EditPrice;
