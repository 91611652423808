import { LeftOutlined } from '@ant-design/icons';
import { Drawer, Spin, Button } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import styles from './index.less';
import type { ReturnGoodsItem } from './interface';
import type Store from './store';

interface Props {
  store: Store;
}

interface GoodsProps {
  item: ReturnGoodsItem;
  jumpToStyleLib: (goodsNo: string) => void;
}

const ReturnGoods = observer((props: Partial<Props>): JSX.Element => {
  const { visible,
    loading,
    onDrawerClose,
    orderNum,
    orderType,
    goodsList,
    mallSaleOrderGoodsNum,
    mallReturnRightsNo,
    returnServicePrice,
    jumpToStyleLib,
    checkAssociatedOrder,
    returnDeadLineTime } = props.store;
  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      closeIcon={null}
      mask
      maskClosable
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
      onClose={onDrawerClose}
      open={visible}
      width={400}
    >
      <div className={styles.drawerHeader}>
        <div className={styles.backContainer}>
          <LeftOutlined
            className={styles.backIcon}
            onClick={onDrawerClose}
          />
        </div>
        <span>
          无忧退货
        </span>
      </div>
      <Spin
        spinning={loading}
        tip="加载中..."
        wrapperClassName={styles.spin}
      >
        <div className={styles.drawerContent}>
          <div className={styles.top}>
            {
              orderType === 2 && (
                <div className={`${styles.lh16} ${styles.mb8}`}>
                  {`无忧退货编号：${mallReturnRightsNo}`}
                  {Boolean(orderNum) && (
                    <a
                      onClick={() => checkAssociatedOrder(mallReturnRightsNo)}
                      style={{ marginLeft: '16px' }}
                    >
                      查看关联订单
                    </a>
                  )}
                </div>
              )
            }
            {orderType === 2 && (
              <div className={styles.total}>
                {`${orderNum || '-'}个订单，${mallSaleOrderGoodsNum ?? '-'}件商品可无忧退货，服务费为：`}
                <span className={styles.redColor}>
                  {`¥${returnServicePrice ?? '-'}`}
                </span>
              </div>
            )}
            {orderType === 1 && (
              <div className={styles.total}>
                {`本单无忧退货共${mallSaleOrderGoodsNum ?? '-'}件，服务费为：`}
                <span className={styles.redColor}>
                  {`¥${returnServicePrice ?? '-'}`}
                </span>
              </div>
            )}
            {orderType === 1 && (
              <div className={styles.lh16}>
                {`${returnDeadLineTime} 前可享无理由退货`}
              </div>
            )}
          </div>
          <div>
            {goodsList.map((el: ReturnGoodsItem) => {
              return (
                <GoodsCard
                  item={el}
                  jumpToStyleLib={jumpToStyleLib}
                  key={el.goodsId}
                />
              );
            })}
          </div>
        </div>
      </Spin>
    </Drawer>
  );
});

const GoodsCard = (props: GoodsProps): JSX.Element => {
  const { goodsId, goodsNo, goodsName, mainPicUrl, returnedNum, returnTotalNum, sellerOuterNo, shopName } = props.item;
  const { jumpToStyleLib } = props;
  return (
    <div
      className={styles.goodsCard}
      onClick={() => jumpToStyleLib(`${goodsId}`)}
    >
      <div className={`${styles.flex}`}>
        <img
          className={styles.goodsImg}
          src={mainPicUrl ?? ''}
        />
      </div>
      <div className={`${styles.lh16} ${styles.color6D} ${styles.ml8}`}>
        <div className={`${styles.ellipsis} ${styles.mt2} ${styles.color2B}`}>
          {goodsName}
        </div>
        <div className={`${styles.mt2}`}>
          {`货号：${sellerOuterNo}`}
        </div>
        <div className={`${styles.mt2}`}>
          {`档口：${shopName}`}
        </div>
        <div>
          <span className={`${styles.mr12}`}>
            {`无忧退${returnTotalNum}件`}
          </span>
          <span>
            {`已退${returnedNum}件`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReturnGoods;
