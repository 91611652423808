import type { FormInstance } from 'antd';
import { InputNumber, message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { EgGridModel, ImgFormatter } from 'egenie-utils';
import { observable, action, computed } from 'mobx';
import React from 'react';
import api from '../../../../utils/api';
import type ChooseStyleStore from '../../store';
import styles from './index.less';
import type { ManualShipInfo, DetailVo, Options } from './types';

export default class ManuallyShipStore {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable private parent: ChooseStyleStore;

  @observable public modalLoading = false;

  @observable public confirmLoading = false;

  @observable public visible = false;

  @observable private orderId: number;

  @observable public type: 'select' | 'distribute';

  @observable private ref: FormInstance;

  @observable private orderList: DetailVo[] = []; // 展示的订单列表

  @observable public courierOptions: Options[] = [];

  private APIs = {
    select: {
      query: api.manuallyShipInfo,
      save: api.manuallyShip,
    },
    distribute: {
      query: api.manuallyShipDistributeShipInfo,
      save: api.manuallyShipDistributeShip,
    },
  };

  // 所有实发数量和为0，禁用提交按钮
  @computed public get confirmDisable() {
    const total = this.orderList.reduce((pre, cur) => {
      return (cur?.shipCount || 0) + pre;
    }, 0);
    return !total;
  }

  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action public onModalShow = (orderId, type: 'select' | 'distribute'): void => {
    Object.assign(this, {
      visible: true,
      orderId,
      type,
    });
    this.queryOrderInfo();
  };

  @action public onModalClose = (): void => {
    Object.assign(this, {
      visible: false,
      orderList: [],
    });
    this.gridModel.rows = [];
    this.ref?.resetFields();
  };

  @action public onOk = (): void => {
    if (this.checkHasNull()) {
      return;
    }
    this.ref?.validateFields().then(({ courier, courierNumber, settle }) => {
      this.confirmLoading = true;

      // 处理提交数据
      const items = this.orderList?.map((el) => {
        return {
          deliverNum: el.shipCount,
          orderDetailId: el.orderDetailId,
        };
      });

      // 获取courierName
      const selectCourier = this.courierOptions.find((el) => (el.value === courier));
      request({
        url: this.APIs[this.type]?.save,
        method: 'post',
        data: {
          courierName: selectCourier?.label,
          orderId: this.orderId,
          cpCode: courier,
          settle,
          courierOrderNo: courierNumber,
          items: items || [],
        },
      }).then((res: BaseData<unknown>) => {
        message.success(res.info || '操作成功');
        this.onModalClose();
        this.parent?.handleQuery();
      })
        .finally(() => {
          this.confirmLoading = false;
        });
    });
  };

  // 请求订单详情
  @action private queryOrderInfo = async(): Promise<void> => {
    this.modalLoading = true;
    try {
      const res = await request<BaseData<ManualShipInfo>>({ url: this.APIs[this.type]?.query(this.orderId) });
      const { couriers, detailVos } = res?.data;
      const courierOptions = [];
      for (const key in couriers) {
        if (Object.prototype.hasOwnProperty.call(couriers, key)) {
          const element = couriers[key];
          courierOptions.push({
            label: element,
            value: key,
          });
        }
      }
      this.orderList = detailVos?.map((el) => { // 发货数量默认为下单数量
        return {
          ...el,
          shipCount: el?.num || 0,
        };
      });

      this.courierOptions = courierOptions;
      this.gridModel.rows = [...this.orderList];
    } catch (err) {
      console.log(err);
    }
    this.modalLoading = false;
  };

  public gridModel = new EgGridModel({
    columns: [
      {
        key: 'goodsInfo',
        name: '商品信息',
        width: 300,
        formatter: ({ row }) => {
          const { skuPic, skuName, sellerOuterNo } = row;
          return (
            <div className={styles.goodsInfo}>
              <ImgFormatter
                borderRadius="4px"
                height={56}
                value={skuPic || ' '}
                width={56}
              />
              <div className={styles.info}>
                <div
                  className={`${styles.productItemName} ${styles.lh18}`}
                  title={skuName}
                >
                  {skuName}
                </div>
                <div className={`${styles.lh18}`}>
                  {`货号 ${sellerOuterNo || ''}`}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        key: 'skuSpecification',
        name: '规格',
        width: 120,
      },
      {
        key: 'num',
        name: '下单数量',
        width: 120,
      },
      {
        key: 'shippedQuantity',
        name: '发货数量',
        formatter: ({ row }) => {
          const { num, orderDetailId, shipCount } = row;
          return (
            <div className={styles.shipCount}>
              {this.type === 'select' && (
                <>
                  <InputNumber
                    max={num}
                    min={0}
                    onChange={(shipCount) => this.onShipCountChange(orderDetailId, shipCount)}
                    precision={0}
                    value={shipCount}
                  />
                  {shipCount === null && (
                    <span className={styles.errorTips}>
                      请输入发货数量
                    </span>
                  )}
                </>
              )}
              {this.type === 'distribute' && (
                <span>
                  {shipCount}
                </span>
              )}

            </div>
          );
        },
      },
    ],
    primaryKeyField: 'orderDetailId',
    showCheckBox: false,
    showQuickJumper: false,
    showPager: false,
    showGridOrderNo: false,
    rowHeight: 72,
  });

  // 发货数量改变
  private onShipCountChange = (orderDetailId: number, shipCount: number | string | null): void => {
    const orderItem = this.orderList.find((el) => el.orderDetailId === orderDetailId);
    if (typeof (shipCount) === 'string') {
      orderItem.shipCount = 0;
    } else {
      orderItem.shipCount = shipCount;
    }
    this.gridModel.rows = [...this.orderList];
  };

  // 判断是否有未输入发货数量弹窗
  private checkHasNull = (): boolean => {
    return this.orderList.some((el) => el.shipCount === null);
  };
}
