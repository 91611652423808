import React, { useState } from 'react';
import { Button, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined, RightOutlined } from '@ant-design/icons';
import type Store from './store';
import noPic from '../../assets/images/noPic.png';
import { ImgFormatter } from 'egenie-utils';
import EditPrice from './components/mainList/editPrice';
import styles from './components/mainList/index.less';
import { nanoid } from 'nanoid';

const EditPriceCom: React.FC<{ handleQuery; mallSaleOrderDetailId; mallSaleOrderId; price; }> = (props) => {
  const {
    handleQuery,
    mallSaleOrderDetailId,
    mallSaleOrderId,
    price,
  } = props;
  const [
    openUpdateSame,
    setOpenUpdateSame,
  ] = useState(true);
  const changeOpenUpdateSame = (checked: boolean) => {
    setOpenUpdateSame(checked);
  };
  return (
    <EditPrice
      changeOpenUpdateSame={changeOpenUpdateSame}
      handleQuery={handleQuery}
      mallSaleOrderDetailId={mallSaleOrderDetailId}
      mallSaleOrderId={mallSaleOrderId}
      openUpdateSame={openUpdateSame}
      price={price}
    />
  );
};

export const ITEM_HEADER_COLUMNS = (context: Store) => {
  return [
    {
      width: '25%',
      key: 'mallSaleOrderNo',
      rowFormatter: (row) => {
        return (
          <>
            <span>
              订单编号:
              {' '}
              {row?.mallSaleOrderNo}
            </span>
            <CopyToClipboard
              onCopy={() => {
                message.success('复制成功');
              }}
              text={row?.mallSaleOrderNo}
            >
              <CopyOutlined className={styles.iconStyle}/>
            </CopyToClipboard>
          </>
        );
      },
    },
    {
      width: '12%',
      key: 'createdTime',
      rowFormatter: (row) => {
        return (
          <span style={{ marginLeft: '30px' }}>
            下单时间:
            {row?.createdTime}
          </span>
        );
      },
    },
    {
      width: '12%',
      key: 'shopName',
      rowFormatter: (row) => {
        return (
          <span style={{ marginLeft: '30px' }}>
            卖家名称:
            {row?.shopName}
          </span>
        );
      },
    },
    {
      width: '14%',
      key: 'orderType',
      rowFormatter: (row) => {
        return (
          <>
            {row?.outerShopType === 1 && (
              <span
                className={`${styles.orderType} ${styles.greenTag}`}
              >
                青
              </span>
            )}
            {row?.orderType === 2 && (
              <span
                className={`${styles.orderType} ${styles.bg02a}`}
              >
                代发订单
              </span>
            )}
            {row?.orderType === 1 && (
              <span
                className={`${styles.orderType} ${styles.bgf59}`}
              >
                手工下单
              </span>
            )}
            {row?.orderType === 3 && (
              <span
                className={`${styles.orderType} ${styles.bgf59}`}
              >
                衫数样衣订单
              </span>
            )}
            {row.noCareReturnable && (
              <span
                className={styles.returnGoods}
                onClick={() => context.onClickReturnGoods(row?.mallSaleOrderId, row?.orderType)}
              >
                无忧退货
                <RightOutlined/>
              </span>
            )}
          </>
        );
      },
    },
    {
      width: '8%',
      key: 'retailerName',
      rowFormatter: (row) => {
        return (
          <span
            onClick={() => {
              window.top.egenie.openTab(`/egenie-boss/retailerTenantManagement?activeKey=2&ids=${row?.retailerTenantId || ''}`, 53, '零售商租户管理');
            }}
            style={{ marginLeft: 12 }}
          >
            零售商：
            <span className={styles.retailerName}>
              {row?.retailerName}
            </span>
          </span>
        );
      },
    },
  ];
};

export const GRID_COLUMNS = (context: Store) => {
  return [
    {
      width: 25,
      name: '商品信息',
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <div className={styles.productImgWrapper}>
            <ImgFormatter
              height={56}
              value={detail.skuPic || noPic}
              width={56}
            />
            <div className={styles.productItemInfo}>
              <div
                className={styles.productItemName}
                title={detail.goodsName}
              >
                商品名称：
                {detail.goodsName}
              </div>
              <div
                className={styles.productItemName}
                title={detail.sellerOuterNo}
              >
                货号：
                {detail.sellerOuterNo}
              </div>
              <div
                className={styles.productItemName}
                title={detail.goodsSpec}
              >
                规格：
                {detail.goodsSpec}
              </div>
              <div
                className={styles.productItemName}
                title={detail.skuNo}
              >
                SKU编码：
                {detail.skuNo}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      width: 10,
      name: '单价（元）',
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <div className={styles.leftInfo}>
            <span>
              {`¥${detail.price}`}
            </span>
            {row.orderStatus === 0 && (
              <EditPriceCom
                handleQuery={context.handleQuery}
                mallSaleOrderDetailId={detail.mallSaleOrderDetailId}
                mallSaleOrderId={row.mallSaleOrderId}
                price={detail.price}
              />
            )}
          </div>
        );
      },
    },
    {
      width: 10,
      name: '数量',
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <div style={{ lineHeight: '15px' }}>
            <p>
              下单：
              {detail.originNum || 0}
            </p>
            {row.deliveryMode === 1 && (
              <p>
                已拿：
                <span style={{ color: detail.cloudWmsOriginNum === 0 ? 'red' : 'green' }}>
                  {detail.cloudWmsOriginNum || 0}
                </span>
              </p>
            )}
          </div>
        );
      },
    },
    {
      width: 10,
      name: '唯一码',
      merge: false,
      detailFormatter: (detail, row) => {
        return detail?.uniqueCodes?.map((e) => {
          return (
            <div key={nanoid()}>
              <span>
                {e}
              </span>
              <CopyToClipboard
                onCopy={() => {
                  message.success('复制成功');
                }}
                text={e}
              >
                <CopyOutlined className={styles.iconStyle}/>
              </CopyToClipboard>
            </div>
          );
        });
      },
    },
    {
      width: 120,
      name: '发货方式',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div className={styles.deliveryType}>
            <div>
              {row?.deliveryTypeStr}
            </div>
            {row?.deliveryMode === 1 && (
              <div>
                {row?.cloudWarehouseName}
              </div>
            )}
            {row?.deliveryMode === 3 && (
              <div className={styles.greenTag}>
                {row?.deliveryModeName}
              </div>
            )}
          </div>
        );
      },
    },
    {
      width: 120,
      name: '买家信息',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div
            className={styles.orderInfo}
            style={{ flexDirection: 'column' }}
          >
            {row.buyerName}
          </div>
        );
      },
    },
    {
      width: 120,
      name: '订单状态',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div
            className={styles.orderInfo}
          >
            <div className={styles.orderInfoContent}>
              <div>
                {row.orderStatusName}
              </div>
              <Button
                onClick={() => {
                  window.top.egenie.openTab(`/egenie-ts-gallery/chooseStyleOrderDetail?mallSaleOrderId=${row.mallSaleOrderId}&posPayTypeName=${row.posPayTypeName}&cloudWarehouseId=${row.cloudWarehouseId}`, 'chooseStyleOrderDetail', '订单详情');
                }}
                type="link"
              >
                订单详情
              </Button>

              {row.orderStatus > 1 && row.deliveryType !== 1 && (
                <Button
                  onClick={() => {
                    context.courierQueryStore.toggleOrderModal(true, row.mallSaleOrderId, 1);
                  }}
                  type="link"
                >
                  查看物流
                </Button>
              )}
            </div>
          </div>
        );
      },
    },
    {
      width: 120,
      name: '实收款',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div className={styles.payment}>
            <div className={styles.orderInfoContent}>
              <div>
                ¥
                {row.payableAmount}
              </div>
              <div>
                （运费：¥
                {' '}
                {row.postAmount}
                ）
              </div>
              <div>
                {row.posPayTypeName}
              </div>
            </div>
          </div>
        );
      },
    },
  ];
};
