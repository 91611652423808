import { Modal, Form, Input } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type RemarkModalStore from './store';

const RemarkModal: React.FC<{ store: RemarkModalStore; }> = observer((props) => {
  const { visible, confirmLoading, onOk, onCloseModal, setRef } = props.store;
  return (
    <Modal
      confirmLoading={confirmLoading}
      maskClosable={false}
      okText="保存"
      onCancel={onCloseModal}
      onOk={onOk}
      open={visible}
      title="备注"
    >
      <Form ref={setRef}>
        <Form.Item name="remark">
          <Input.TextArea
            maxLength={200}
            placeholder="请输入"
            showCount
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default RemarkModal;
