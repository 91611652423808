import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import api from '../../../../utils/api';
import type ParentStore from '../../store';
import type { ReturnGoodsItem, ReturnGoodsList } from './interface';

export default class ReturnGoodsStore {
  constructor(props: ParentStore) {
    this.parentStore = props;
  }

  @observable public parentStore: ParentStore;

  @observable public visible = false;

  @observable public loading = false;

  @observable public orderType: number;

  @observable public returnServicePrice: number; // 退货总服务费

  @observable public mallSaleOrderGoodsNum: number; // 退货总件数

  @observable public mallReturnRightsNo = ''; // 无忧退货编号

  @observable public orderNum: number; // 订单数

  @observable public returnDeadLineTime = ''; // 截止退货时间

  @observable public goodsList: ReturnGoodsItem[] | [] = []; // 退货列表

  @action public onDrawerShow = (orderId: number | string, orderType: number): void => {
    this.visible = true;
    this.orderType = orderType;
    this.queryGoodsList(orderId);
  };

  @action public onDrawerClose = (): void => {
    this.visible = false;
    this.initData();
  };

  @action public queryGoodsList = async(orderId: string | number): Promise<void> => {
    this.loading = true;
    const res: BaseData<ReturnGoodsList> = await request({ url: api.queryReturnGoodsList(orderId) });
    const {
      returnServicePrice,
      mallSaleOrderNum,
      mallSaleOrderGoodsNum,
      goodsVoList,
      mallReturnRightsNo,
      returnDeadLineTime,
    } = res.data;
    Object.assign(this, {
      returnServicePrice,
      orderNum: mallSaleOrderNum,
      mallSaleOrderGoodsNum,
      goodsList: goodsVoList,
      mallReturnRightsNo,
      returnDeadLineTime,
    });
    this.loading = false;
  };

  @action public initData = (): void => {
    this.goodsList = [];
    this.returnServicePrice = null;
    this.mallSaleOrderGoodsNum = null;
    this.orderType = null;
    this.returnDeadLineTime = '';
    this.loading = false;
  };

  @action public jumpToStyleLib = (goodsId: string): void => {
    window.top.egenie.openTab(`/egenie-boss/styleLibrary?goodsId=${goodsId}`, '55', '款式库管理');
  };

  // 查看关联订单
  public checkAssociatedOrder = (orderIds: string): void => {
    this.parentStore.tabOrderStatus = -1;
    this.parentStore.programme.reset();
    this.parentStore.programme?.filterItems?.updateFilterItem([
      {
        type: 'input',
        field: 'mallReturnOrderNo',
        value: orderIds,
      },
      {
        field: 'orderStatus',
        showItem: true,
      },
    ]);

    this.parentStore.handleQuery();
  };
}
