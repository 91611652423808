import { observer } from 'mobx-react';
import React, { Component } from 'react';
import styles from './index.less';

@observer
export default class TabHader extends Component<{ store; }> {
  render(): JSX.Element {
    const { orderStatusList, handleTabsClick, tabOrderStatus } = this.props.store;
    return (
      <div className={styles.tabsWrapper}>
        <div
          className={`${styles.eachItem} ${tabOrderStatus < 0 && styles.activeItem}`}
          onClick={() => {
            handleTabsClick(-1);
          }}
        >
          全部
        </div>
        {
          orderStatusList?.map((item) => (
            <div
              className={`${styles.eachItem} ${tabOrderStatus === item.code && styles.activeItem}`}
              key={item.code}
              onClick={() => {
                handleTabsClick(item.code);
              }}
            >
              {item.name}
              {Object.prototype.toString.call(item.number) === '[object Number]' ? `(${item.number})` : '' }
            </div>
          ))
        }
      </div>
    );
  }
}
